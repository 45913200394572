@charset "UTF-8";

.shareButtonRoot {
    width: 86px;
    height: 32px;
    border-radius: 4px;
    background: radial-gradient(106.04% 106.06% at 100.1% 90.19%,#FD6262 33.63%,#e7b2b2 100%);;
    padding: 5px 12px;
  }
  
  .shareButtonRoot:hover {
    background: linear-gradient(135deg, #FD6262 0%, #ea8282 51.04%, #e7b2b2 100%);
  }
  
  .shareButtonRoot span {
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    color: #FFFFFF;
  }

  .shareButtonRoot i, .shareButtonRoot:hover i {
    color: #FFFFFF !important;
  }
  
  .historyButtonRoot {
    width: 180px;
    border: 1px solid #D1D1D1;
  }

  .historyButtonRoot:hover {
    border: 1px solid #D1D1D1;
  }

  .historyButtonRoot:active {
    border: 1px solid #D1D1D1;
  }

  @media (max-width: 480px) {
    .shareButtonRoot {
      width: auto; 
      padding: 5px 8px; 
    }

    .historyButtonRoot {
      width: auto;
      padding: 0 8px  
    }
  }